<template>
  <div>
    <h4>关联简称（{{ list.length }}）</h4>
    <span v-if="hasAction('edit-team-evaluation-unit-abbreviation')">
      <template v-for="item in list">
        <a-popconfirm
          :key="item.id"
          :visible="item.closeConfirmVisible"
          title="确认删除该简称？"
          ok-text="确认"
          cancel-text="取消"
          @confirm="doDelete(item)"
          @cancel="cancel(item)"
          @visibleChange="handleVisibleChange(item)"
        >
          <a-icon
            slot="icon"
            type="close-circle"
            theme="filled"
            style="color: red"
          />
          <a-tag
            @close.prevent="handleClose(item)"
            :closable="true"
            style="margin-bottom: 10px"
          >
            {{ item.abbreviation }}
          </a-tag>
        </a-popconfirm>
      </template>
      <a-input
        v-if="inputVisible"
        ref="input"
        type="text"
        size="small"
        :style="{ width: '78px' }"
        :value="inputValue"
        @change="handleInputChange"
        @blur="handleInputConfirm"
        @keyup.enter="handleInputConfirm"
      />
      <a-tag
        v-else
        style="background: #fff; border-style: dashed; margin-bottom: 10px"
        @click="showInput"
      >
        <a-icon type="plus" /> 新增简称
      </a-tag>
    </span>
    <span v-else>
      <a-tag v-for="item in list" :key="item.id" style="margin-bottom: 10px">
        {{ item.abbreviation }}
      </a-tag>
    </span>
  </div>
</template>

<script>
// eslint-
import {
  getTeamGradeUnitAbbr,
  addTeamGradeUnitAbbr,
  deleteTeamGradeUnitAbbr,
} from "@/api/evaluation";
export default {
  name: "index",
  components: {},
  props: {},
  data() {
    return {
      list: [],
      inputVisible: false,
      inputValue: null,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getTeamGradeUnitAbbr({ entityId: this.$route.query.id }).then((res) => {
        this.list = res.data;
        this.list.forEach((item) => {
          this.$set(item, "closeConfirmVisible", false);
        });
      });
    },
    doDelete(item) {
      item.closeConfirmVisible = false;
      deleteTeamGradeUnitAbbr({ abbreviationId: item.id }).then((res) => {
        if (res.code == 0) {
          this.$message.success("删除成功！");
          this.loadData();
        } else {
          this.$message.error("删除失败！");
        }
      });
    },
    cancel(item) {
      item.closeConfirmVisible = false;
    },
    handleMouseLeave(item) {
      item.closeConfirmVisible = false;
    },
    handleVisibleChange(item) {
      item.closeConfirmVisible = !item.closeConfirmVisible;
    },
    handleClose(item) {
      item.closeConfirmVisible = true;
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(function () {
        this.$refs.input.focus();
      });
    },
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },
    handleInputConfirm() {
      const inputValue = this.inputValue;
      this.inputVisible = false;
      if (this.inputValue == null) return;
      addTeamGradeUnitAbbr({
        abbreviation: inputValue,
        entityId: this.$route.query.id,
      }).then(() => {
        this.inputValue = null;
        this.loadData();
      });
    },
  },
};
</script>

<style scoped>
</style>